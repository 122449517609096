<template>
  <div class="payContent">
    <div class="goodsPrice">
      {{amount}}
    </div>
    <div class="trade_line"></div>
    <div class="trade_list">
      <div class="trade_item">
        <span class="trade_label">订单号：</span>
        <span class="trade_item_info">{{trade_no}}</span>
      </div>
      <div class="trade_item">
        <span class="trade_label">订单信息：</span>
        <span class="trade_item_info">{{tradeName}}</span>
      </div>

    </div>
    <div @click="goPage">测试</div>
    <div class="pay_submit"
         @click="payTrade">去支付</div>

  </div>
</template>

<script>
import { payTrade } from "@/api/pay"
export default {
  data () {
    return {
      tradeName: '',
      trade_no: '',
      amount: '',
      paymetchods: '',
      openId: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    goPage (url) {
      window.location.href = 'weixin://wxpay/bizpayurl?pr=GbrluG4zz'
    },
    init () {
      this.tradeName = this.$route.query.product_subject
      this.trade_no = this.$route.query.out_trade_no
      this.amount = '¥' + this.changeMoney(this.$route.query.amount)
      this.paymetchods = this.userAgentComfirm()
    },
    changeMoney (num) {
      var regexp = /(?:\.0*|(\.\d+?)0+)$/
      if (num > 1000000) {
        num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
        return num + '万'
      } else {
        num = (num / 100).toFixed(2)
        num = num.replace(regexp, '$1')
        return num
      }
    },
    payTrade () {
      this.getCode()
      // const appId = 'wxda1e5814941a8893'
      // const msg = {
      //   out_trade_no: this.trade_no
      // }
      // if (typeof window.WeixinJSBridge === 'undefined') {
      //   if (document.addEventListener) {
      //     document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady);
      //   }
      // } else {
      //   this.onBridgeReady();
      // }
      // this.getCode()
      // payTrade(msg).then(res => {
      //   alert(8989)
      //   if (this.paymetchods === 'Alipay') {
      //     const div = document.createElement('div')
      //     div.innerHTML = res.data.result
      //     document.body.appendChild(div)
      //     div.style.display = "none"
      //     document.forms.alipaysubmit.submit()
      //   } else {
      //     console.log(6666)
      //     this.getCode()
      //     // alert(777)
      //     // this.$route.push('weixin://wxpay/bizpayurl?pr=GbrluG4zz')
      //     // alert(888)
      //   }

      // })
    },
    userAgentComfirm () {
      // 小写化
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') > 0) {
        return 'WeiXIN';
      }
      if (ua.indexOf('alipayclient') > 0) {
        return 'Alipay';
      }
      return 'false';
    },
    getCode () {
      const appid = 'wxda1e5814941a8893' // 服务号id
      const local = window.location.href
      const openId = this.GetUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      if (openId == null || openId === '') {
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect'
      } else {
        this.openId = openId
      }
    },
    GetUrlParam (code) {
      var reg = new RegExp('(^|&)' + code + '=([^&]*)(&|$)')
      let url = window.location.href.split("#")[0]
      let search = url.split('?')[1]
      if (search) {
        var r = search.substr(0).match(reg)
        if (r !== null) return unescape(r[2])
        return null
      } else {
        return null
      }
    }
  }
}
</script>


<style lang="scss" scope>
.payContent {
  width: 100%;
  height: 100vh;
  background: #fff;
  .goodsPrice {
    padding-top: 128px;
    font-size: 23px;
    font-family: "SimHei";
    text-align: center;
    font-weight: bold;
    color: #575757;
    letter-spacing: 5px;
    box-sizing: border-box;
  }
  .trade_line {
    width: 100%;
    height: 4px;
    background: #fbfbfb;
    margin-top: 60px;
  }
  .trade_list {
    width: 100%;
    .trade_item {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #e4e4e4;
      padding: 0 15px;
      font-family: "SimHei";
      .trade_label {
        float: left;
        color: #a8a8a8;
        font-size: 16px;
        letter-spacing: 3px;
      }
      .trade_item_info {
        float: right;
        color: #a8a8a8;
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }
  .pay_submit {
    width: 90%;
    height: 50px;
    font-size: 18px;
    font-family: "SimHei";
    color: #fff;
    line-height: 50px;
    background: #ff7391;
    margin: 0 auto;
    opacity: 1;
    border-radius: 6px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    text-align: center;
  }
}
</style>